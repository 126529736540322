import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import clip from '../images/bg.mp4'

import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => (
  <Layout>
    <Seo title="Anasayfa" />

    <div className="hero">
      <div className="backgroundvideo">
        <video loop muted autoPlay>
          <source src={clip} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="hero-text">
        <div className="slogan">
          <h1>
            Hey, <br />
            Selam.
          </h1>
        </div>
        <div className="header-text">
          <p>
            Biz, strateji, deneyim ve yeniliği birleştiren bir tavırla
            markaların yaratılması ve tanıtılması konusunda uzmanlaşmış bir
            ajansız.
          </p>
        </div>
      </div>
    </div>
    <div className="seperator">
      <div className="container">
        <ul className="works">
          <li>Markalama</li>
          <li>+</li>
          <li>Logo</li>
          <li>+</li>
          <li>Görsel Kimlik</li>
          <li>+</li>
          <li>Web Tasarım</li>
          <li>+</li>
          <li>Video Prodüksiyon</li>
          <li>+</li>
          <li>Dijital Pazarlama</li>
          <li className="mobil-out">+</li>
          <li className="mobil-out">Arayüz Tasarımı</li>
          <li className="mobil-out">+</li>
          <li className="mobil-out">Grafik Tasarım</li>
          <li className="mobil-out">+</li>
          <li className="mobil-out">Mobil Uygulama</li>
          <li className="mobil-out">+</li>
          <li className="mobil-out">İllustrasyon</li>
          <li className="mobil-out">+</li>
          <li className="mobil-out">Fotoğraf Çekimi</li>
        </ul>
      </div>
    </div>
    <div className="container">
      <div className="projects">
        <h3>Son Projeler</h3>
        <Link to="/works/">
          <div className="project-list">
            <StaticImage
              src="../works/1/featured.jpg"
              alt="Bartu Tabakcı"
              quality="100"
              className="first"
            />
            <StaticImage
              src="../works/2/featured.jpg"
              alt="Bartu Tabakcı"
              className="second"
              quality="100"
            />
            <StaticImage
              src="../works/3/featured.jpg"
              alt="Bartu Tabakcı"
              className="third"
              quality="100"
            />
            <StaticImage
              src="../works/1/featured.jpg"
              alt="Bartu Tabakcı"
              className="fourth"
              quality="100"
            />
          </div>
        </Link>
        <div className="others">
          <Link to="/works/">
            <h3>Diğer Çalışmalarımız →</h3>
          </Link>
          <h2>
            <span>
              Önemli olan sadece ne yaptığımız değil. <br />
            </span>
            Ayrıca sizin için ne yapacağımızdır.
          </h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
